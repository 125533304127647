<template>
    <div>
        <b-form-group :label="$t('name')">
            <div class="label-as-input">{{ getObjectValue(student, 'name') }}</div>
        </b-form-group>
        <b-form-group :label="$t('surname')">
            <div class="label-as-input">{{ getObjectValue(student, 'surname') }}</div>
        </b-form-group>
        <b-form-group :label="$t('course_name')">
            <div class="label-as-input">{{ getLocaleText(student, 'course_name') }}</div>
        </b-form-group>
        <b-form-group :label="$t('grade_entry')">
            <grade-selectbox v-model="data.letter_grade" value-type="grade" :grading-system-id-required="false"/>
        </b-form-group>


        <div class="d-flex justify-content-center mt-5">
            <b-button variant="primary"
                      @click="addExamScore"
                      :disabled="loading">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox.vue";
import MakeUpExamService from "@/services/MakeUpExamService";

export default {
    components: {GradeSelectbox},
    props: {
        student: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            data: {},
            loading: false
        }
    },
    methods: {
        addExamScore() {
            var checkStudent;
            if (this.student) {

                this.loading = true;
                checkStudent = Object.keys(this.student);
                if (checkStudent.length) {


                    var formData = new FormData();

                    formData.append(`data[${0}][make_up_exam_id]`, this.student.id)
                    formData.append(`data[${0}][letter_grade]`, this.data.letter_grade)


                    MakeUpExamService.storeEnteredLetterGrade(formData)
                        .then(() => this.$emit('success'))
                        .catch(err => this.showErrors(err))
                        .finally(() => this.loading = false);
                }
            }
        }
    }
}
</script>
