<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('make_up_exam')" :isColumns="true" :is-filter="true" :is-columns="true"
                    :actions="subMenu" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('make_up_exam')" :isColumns="true" :is-filter="true" :is-columns="true"
                    :actions="subMenu" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter"
                @filterClear="filterClear()" :export-excel="checkPermission('makeupexam_makeupexamsexport')" @exportExcel="exportExcel">
                <ValidationObserver ref="filterForm">
                    <b-row>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('student_number')">
                                <b-form-input v-model="datatable.queryParams.filter.student_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('name')">
                                <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('surname')">
                                <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('course_code')">
                                <course-auto-complete v-model="datatable.queryParams.filter.course_code"
                                    resultType="code" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('is_take_exam')">
                                <YesNoSelectbox v-model="datatable.queryParams.filter.is_take_exam" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('letter_grade')">
                                <grade-selectbox :grading-system-id-required="false"
                                    v-model="datatable.queryParams.filter.letter_grade"
                                                 value-type="grade"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('final_letter_grade')">
                                <grade-selectbox :grading-system-id-required="false"
                                                 value-type="grade"
                                    v-model="datatable.queryParams.filter.final_letter_grade" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('semester')">
                                    <semesters-selectbox v-model="datatable.queryParams.filter.semester_id" :setActive="true">
                                    </semesters-selectbox>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6" md="4">
                            <b-form-group :label="$t('program_level')">
                                <ValidationProvider name="level" rules="required" v-slot="{ errors }">
                                    <ProgramLevelSelectboxLYL hasD :validateError="errors[0]"
                                        v-model="datatable.queryParams.filter.level"></ProgramLevelSelectboxLYL>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable ref="datatable" :isLoading.sync="datatable.isLoading" :columns="datatable.columns"
                :rows="datatable.rows" :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                v-show="datatable.showTable" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"></datatable>
            <CommonModal ref="addExamScore" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal"
                v-if="checkPermission('makeupexam_entrylettergrade')">
                <template v-slot:CommonModalTitle>
                    {{ $t('add_exam_score') }}
                </template>
                <template v-slot:CommonModalContent>
                    <add-exam-score :student="student" v-if="process_exam_score"
                        @success="addedExamScoreAsSucces"></add-exam-score>
                </template>
            </CommonModal>
            <CommonModal ref="calcPublishModal" :onHideOnlyX="true" @bHideModalHeaderClose="hideCalcPublishModal">
                <template v-slot:CommonModalTitle>
                    {{ modalAction == 'calculate' ? $t('calculate') : modalAction == 'publish' ? $t('publish') : null }}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="modalForm">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group :label="$t('program_level')">
                                        <ValidationProvider name="level" rules="required" v-slot="{ errors }">
                                            <ProgramLevelSelectboxLYL
                                                :noOL="true"
                                                :noD="true"
                                                :validateError="errors[0]"
                                                v-model="programLevel"></ProgramLevelSelectboxLYL>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="12">
                                    <b-form-group :label="$t('faculty')">
                                        <ValidationProvider name="faculty_code" rules="required" v-slot="{ errors }">
                                            <faculty-selectbox
                                                :multiple="true"
                                                :validateError="errors[0]"
                                                v-model="facultyCode"></faculty-selectbox>
                                        </ValidationProvider>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="4" class="mt-2">
                                    <b-button block class="mt-4" variant="primary" @click="modalClick">
                                        {{ modalAction == 'calculate' ? $t('calculate') : $t('publish') }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter"
import Datatable from "@/components/datatable/Datatable"
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete.vue"
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox.vue"
import ProgramLevelSelectboxLYL from "@/components/interactive-fields/ProgramLevelSelectboxLYL"
import CommonModal from "@/components/elements/CommonModal.vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import MakeUpExamService from "@/services/MakeUpExamService"
import AddExamScore from "@/modules/makeUpExam/pages/AddExamScore.vue"
import qs from "qs";
import FacultySelectboxMultiple from "@/components/interactive-fields/FacultySelectboxMultiple.vue";
import JsonExcel from "vue-json-excel";

export default {
    components: {
        FacultySelectboxMultiple,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        ValidationObserver,
        ValidationProvider,
        Datatable,
        CourseAutoComplete,
        YesNoSelectbox,
        GradeSelectbox,
        ProgramLevelSelectboxLYL,
        CommonModal,
        AddExamScore,
        JsonExcel
    },
    metaInfo() {
        return {
            title: this.$t("make_up_exam")
        }
    },
    data() {
        return {
            datatable: {
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-6-line',
                                callback: ({ id }) => {
                                    this.$swal
                                        .fire({
                                            text: this.$t('are_you_sure_to_delete'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                MakeUpExamService.deleteById(id)
                                                    .then((response) => {
                                                        this.$toast.success(this.$t('api. ' + response.data.message))
                                                        this.filter();
                                                    })
                                                    .catch((error) => {
                                                        this.$toast.error(this.$t('api. ' + error.data.message))
                                                    })
                                            }
                                        })
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: "name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: "surname",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "student_number",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: this.getLocaleField('course_name'),
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("letter_grade"),
                        field: "letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("final_letter_grade"),
                        field: "final_letter_grade",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("is_take_exam"),
                        field: "is_take_exam",
                        formatFn: (value) => {
                            if (value == null && value == '') return '-'
                            return value ? this.$t('yes') : this.$t('no')
                        },
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester"),
                        field: "semester_name",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("instructor_name"),
                        field: "instructor_name",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("instructor_surname"),
                        field: "instructor_surname",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("cgpa"),
                        field: "gno",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("program_code"),
                        field: "program_code",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("section"),
                        field: "section_number",
                        hidden: true,
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-id",
                    page: 1,
                    limit: 20,
                },
            },
            student: {},
            process_exam_score: null,
            subMenu: [
                {
                    text: this.$t('calculate'),
                    class: 'ri-device-recover-line',
                    callback: () => {
                        this.showCalcPublishModal('calculate')
                    }
                },
                {
                    text: this.$t('publish'),
                    class: 'ri-hotspot-line',
                    callback: () => {
                        this.showCalcPublishModal('publish')
                    }
                }
            ],
            modalAction: null,
            programLevel: null,
            facultyCode: null
        }
    },
    created() {
        this.filterSet()
    },
    methods: {
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterSet() {
            this.datatable.queryParams.filter = {}
        },
        filterClear(){
            this.datatable.queryParams.filter = {};
            this.getRows();
        },

       async getRows() {
           this.datatable.showTable = true;
           const config = {
               params: {
                   ...this.datatable.queryParams
               },
               paramsSerializer: (params) => qs.stringify(params, { encode: false })
           };
        const isValid = await this.$refs.filterForm.validate();
        if(isValid) {
            return MakeUpExamService.getAll(config)
                .then(response=>{
                    this.datatable.rows=response.data.data
                    this.datatable.total=response.data.pagination.total
                }).finally(() => {
                    this.datatable.isLoading = false;
                });
           }
        },
    async exportExcel(){
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            const isValid = await this.$refs.filterForm.validate();
            if(isValid){
                return MakeUpExamService.excelExport(config)
                    .then(response=>{
                        this.$toast.success(this.$t(response.data.message));
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    }).catch(err=>{
                        this.showErrors(err)
                    })
            }
        },
        showCalcPublishModal(action) {
            this.programLevel = null
            this.facultyCode = null
            this.modalAction = action
            this.$refs.calcPublishModal.$refs.commonModal.show()
        },
        hideCalcPublishModal() {
            this.modalAction = null
            this.programLevel = null
            this.facultyCode = null
            this.$refs.calcPublishModal.$refs.commonModal.hide()
        },
        async modalClick() {
            const isValid = await this.$refs.modalForm.validate()

            if (isValid) {
                if (this.modalAction == 'calculate' && this.programLevel == 'L' && this.checkPermission('makeupexam_calculatemakeupexam')) {
                    MakeUpExamService.calculate({faculty_code: this.facultyCode}).then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else if (this.modalAction == 'calculate' && this.programLevel == 'YL' && this.checkPermission('makeupexam_calculatedegreemakeupexam')) {
                    MakeUpExamService.calculateDegree({faculty_code: this.facultyCode}).then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else if (this.modalAction == 'publish' && this.programLevel == 'L' && this.checkPermission('makeupexam_publishmakeupexam')) {
                    MakeUpExamService.storePublish().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else if (this.modalAction == 'publish' && this.programLevel == 'YL' && this.checkPermission('makeupexam_publishdegreemakeupexam')) {
                    MakeUpExamService.storePublishDegree().then(response => {
                        this.$toast.success(this.$t('proccess_success'))
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                        this.hideCalcPublishModal()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        },
        openAddExamScore(student) {
            this.student = student
            this.process_exam_score = true
            this.$refs.addExamScore.$refs.commonModal.show()
        },
        closeModal() {
            this.process_exam_score = false
            this.getRows()
            this.$refs.addExamScore.$refs.commonModal.hide()
        },
        addedExamScoreAsSucces() {
            this.getRows()
            this.$refs.addExamScore.$refs.commonModal.hide()
        }
    }
}
</script>
